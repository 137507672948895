import { lazy } from 'react';

const options = [
  {
    key: Math.random(),
    path: '/projects',
    component: lazy(() => import('../projects/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/about',
    component: lazy(() => import('../about/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/contact',
    component: lazy(() => import('../contact-us/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/gallery',
    component: lazy(() => import('../gallery/List')),
    exact: true,
  },
];
export default options;
